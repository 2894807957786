<script>
import axios from "axios"
import VueRecaptcha from "vue-recaptcha"
import Config from "/config"

export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      senderName: "",
      senderEmail: "",
      subject: "",
      text: "",
      incidentNumber: "",
      sendClicked: false,
      verified: false,
      sent: false,
      success: false,
    }
  },
  computed: {
    senderNameValid() {
      return !this.sendClicked ? null : this.senderName.length > 0
    },
    senderEmailValid() {
      const emailRegex = Config?.notification?.emailRegex
      return !this.sendClicked ?
        null :
        this.senderEmail.length > 0 && emailRegex?.test(this.senderEmail)
    },
    subjectValid() {
      return !this.sendClicked ? null : this.subject.length > 0
    },
    textValid() {
      return !this.sendClicked ? null : this.text.length > 0
    },
    recaptchaKey() {
      return Config.google.recaptchaKey
    },
  },
  methods: {
    hide() {
      this.$bvModal.hide("ask-us")
    },
    clearEmailData() {
      this.senderName = ""
      this.senderEmail = ""
      this.subject = ""
      this.text = ""
      this.sendClicked = false
      this.sent = false
      this.success = false
      this.verified = false
    },
    sendEmail() {
      this.sendClicked = true

      if (this.senderNameValid && this.senderEmailValid && this.subjectValid && this.textValid) {
        const senderEmail = this.senderEmail.replace(/ /g, "")
        const emailBody = `From: ${this.senderName}, ${senderEmail}\n\nMessage:\n${this.text}`

        const payload = {
          "caller_id": senderEmail,
          "watch_list": senderEmail,
          "short_description": `Field Campaign Dashboard Request: ${this.subject}`,
          "description": emailBody,
          "assignment_group": "ADC User Tools",
        }

        axios({
          method: "post",
          url: `${Config?.notification?.baseURL}/createServiceNowIncident`,
          data: payload,
          config: {headers: {"Content-Type": "application/json;charset=UTF-8"}},
        })
          .then(response => {
            this.incidentNumber = response.data.result.number
            this.sent = true
            if (this.incidentNumber !== "") {
              this.success = true
            }
          })
          .catch(() => {
            this.sent = true
            this.success = false
          })
      }
    },
    verify() {
      this.verified = true
    },
    expired() {
      this.verified = false
    },
  },
}
</script>

<template>
  <div>
    <b-modal
      id="ask-us"
      hide-header
      hide-footer
      no-enforce-focus
      @show="clearEmailData"
      @hidden="hide"
    >
      <h3>Ask us</h3>
      <div v-if="!sent">
        <b-row>
          <b-col>
            <b-form-input
              id="senderName"
              v-model="senderName"
              :state="senderNameValid"
              required
              placeholder="Name"
            />
            <b-form-invalid-feedback
              :state="senderNameValid"
              class="text-left"
            >
              Sender name cannot be empty
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-input
              id="email"
              v-model="senderEmail"
              :state="senderEmailValid"
              type="email"
              required
              placeholder="Email"
              autocomplete="email"
            />
            <b-form-invalid-feedback
              :state="senderEmailValid"
              class="text-left"
            >
              Sender email must be a valid email address
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-input
              id="subject"
              v-model="subject"
              :state="subjectValid"
              required
              placeholder="Subject"
            />
            <b-form-invalid-feedback
              :state="subjectValid"
              class="text-left"
            >
              Subject cannot be empty
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-textarea
              id="message"
              v-model="text"
              :state="textValid"
              type="text"
              rows="6"
              required
              placeholder="Enter your message here."
            />
            <b-form-invalid-feedback
              :state="textValid"
              class="text-left"
            >
              Message cannot be empty
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <VueRecaptcha
              :sitekey="recaptchaKey"
              :loadRecaptchaScript="true"
              @verify="verify"
              @expired="expired"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              class="submitButton primaryFontColor secondaryBackground border-0"
              :disabled="!verified"
              squared
              @click="sendEmail"
            >
              Submit Request
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col class="result text-left">
            <div v-if="success">
              <p>
                Thank you for submitting your support request,
                someone from the ARM Data Center will review and get back to you as soon as possible.
              </p>
              <p>
                You should receive an email soon with a link to your support request in ServiceNow,
                where we track questions and issues.
              </p>
              <p>
                Your support reference number is: <b>{{ incidentNumber }}</b>
              </p>
            </div>
            <div v-else>
              We're sorry, an error occurred when attempting to send your message.
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <a class="closeLink">
            <span @click="hide">
              Close
            </span>
          </a>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<style>
#ask-us {
  text-align: center;
}

#ask-us .modal-body {
  background-color: #ebebeb;
  padding: 40px;
  padding-bottom: 20px;
}

#ask-us h3 {
  color: #414141;
  font-family: "Proxima Nova" !important;
  font-size: 42px;
  font-weight: bold;
  line-height: 50px;
}

#ask-us .row {
  padding-top: 5px;
  padding-bottom: 5px;
}

#ask-us .submitButton {
  width: 220px;
}

#ask-us a {
  color: #414141;
}

#ask-us a.closeLink,
#ask-us a.closeLink:link,
#ask-us a.closeLink:visited {
  text-decoration: underline;
}

#ask-us a.closeLink:hover,
#ask-us a.closeLink:active {
  cursor: pointer;
}

#ask-us .result {
  font-size: 1.1em;
}
</style>
